import React from "react";
import { t } from "i18next";
import { ReverseButton, openSuccessNotification } from "../../components";

const inputStyle =
  "font-bold text-black block w-full px-3 py-3 bg-white border text-sm placeholder-black border-black focus:outline-none";
const twoFaBtnStyle =
  "absolute top-[1px] right-[1px] px-5 py-3 text-sm font-bold bg-[#76C14E] border-[#76C14E] border-r-black";

export const ReferralForm = (props: any) => {
  const {
    selfReferralCode,
    referralCode,
    setReferralCode,
    handleReferral,
    appliedCode,
    usedReferralCode,
  } = props;

  const copyToClipboard = () => {
    const element = document.createElement("textarea");
    element.value = selfReferralCode;
    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    openSuccessNotification(t("referral.copied"));
    document.body.removeChild(element);
  };

  return (
    <div className="max-md:w-[80%] md:w-[30%]">
      <input
        type="text"
        value={selfReferralCode}
        className={`${inputStyle} cursor-pointer`}
        // placeholder={t("login.mobileForm.email")}
        onClick={() => copyToClipboard()}
        readOnly
      />

      {appliedCode ? (
        <div className="relative w-full mt-10 flex flex-row text-sm">
          <p className="mr-2">{t("referral.form.used")}</p>
          <p>{usedReferralCode}</p>
        </div>
      ) : (
        <div className="relative w-full mt-10">
          <input
            type="search"
            id="search-dropdown"
            className={`mt-5 ${inputStyle}`}
            placeholder={t("referral.form.referralCode")}
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
            required
            maxLength={10}
          />

          <ReverseButton
            handleAction={() => handleReferral()}
            customStyle={twoFaBtnStyle}
            buttonText="referral.form.codeConfirm"
          />
        </div>
      )}
    </div>
  );
};
