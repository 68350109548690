import React, { useEffect, useState } from "react";

import { Container } from "src/components";
import { useTransactionStore } from "src/stores";
import { StripeForm } from "./stripe-form";
import { useLocation } from "react-router-dom";

export const StripeCheckout = (props: any) => {
  const { user } = props;
  const location = useLocation();
  const data: any = location.state;
  const { createPaymentOrder, updatePaymentOrder, checkAvailableCoupons } =
    useTransactionStore();
  const [transaction, setTransaction] = useState({});

  useEffect(() => {
    if (!data) return;
    setTransaction(data);
  }, [data, setTransaction]);

  return (
    <Container>
      <StripeForm
        transaction={transaction}
        email={user.email || ""}
        createPaymentOrder={createPaymentOrder}
        checkAvailableCoupons={checkAvailableCoupons}
        updatePaymentOrder={updatePaymentOrder}
      />
    </Container>
  );
};
