import React from "react";
import { t } from "i18next";
import { checkExpireDate, formatDate } from "../../utils";
import { history } from "src/stores";

export const Coupon = (props: any) => {
  // return color and text
  const handleStatus = (expiry_date: string, remaining: number) => {
    const status = checkExpireDate(expiry_date);
    let colorCode = "";
    let text = "";
    switch (status) {
      case 1:
        colorCode = "#F5D560";
        text = t("wallet.menus.almostExpire");
        break;
      case 2:
        colorCode = "#F5D560";
        text = t("wallet.menus.expired");
        break;
      default:
        colorCode = "#76C14E";
        text = t("wallet.left", { remaining: remaining?.toString() });
        if (remaining === 0) {
          colorCode = "#EF637C";
          text = t("wallet.usedAll");
        }
    }

    return (
      <div
        className={`flex flex-row flex-2 items-center justify-center w-full h-full`}
        style={{
          backgroundColor: colorCode,
        }}
      >
        <h3 className="text-sm text-white leading-[12px]">{text}</h3>
      </div>
    );
  };

  const { index, expiry_date, remaining, id, logo, name } = props;
  const couponsStatus = handleStatus(expiry_date, remaining);

  return (
    <div
      key={index}
      className="flex flex-col shadow-[0_0_8px_-3px_rgba(0,0,0,1)] min-w-[300px] w-[0px] mt-12 cursor-pointer"
      onClick={() => history.push(`/wallet/${id}`)}
    >
      <div className="h-full flex-1 border-primary border-b-[5px]">
        <img
          src={logo || "/assets/icons/default-coupon.jpeg"}
          className="h-full object-cover w-[300px] h-[160px] m-auto"
          alt="coupon-img"
        />
      </div>
      <div className="flex flex-col text-left h-full flex-2 justify-between justify-between-md bg-white">
        <div className="px-5 pt-5">
          <h3 className="text-black text-lg tracking-[1px] leading-[20px]">
            {name}
          </h3>
        </div>
        <div className="flex flex-row justify-between items-center">
          {/* expire date */}
          <div className="flex flex-col pl-5 pb-2">
            <h3 className="text-[12px] text-gray leading-[12px]">
              {t(`wallet.expireTitle`)}
            </h3>
            <h3 className="text-sm text-gray mt-1 tracking-normal">
              {formatDate(expiry_date)}
            </h3>
          </div>

          <div className="flex-1" />
          {/* status */}
          {couponsStatus}
        </div>
      </div>
    </div>
  );
};
