import axios from "axios";
import { generalError } from ".";
import * as Configs from "../config";
import {
  errorHandling,
  isResultSuccess,
  refreshNewToken,
  setHeader,
} from "./api";

export class TransactionStore {
  async checkPaymentOrder(payload: any) {
    const url = `${Configs.apiUrl}/payment/check-order`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getLatestTokenPrice(payload: any) {
    const url = `${Configs.apiUrl}/latest-token-price`;
    try {
      const result: any = await axios.post(url, payload);
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async createPaymentOrder(payload: any) {
    const url = `${Configs.apiUrl}/payment/create-order`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async updatePaymentOrder(payload: any) {
    const url = `${Configs.apiUrl}/payment/update-order`;
    try {
      const result: any = await axios.put(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async checkAvailableCoupons(transactionId: string = "") {
    const url = `${Configs.apiUrl}/coupons/check-available-coupons?id=${transactionId}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"];
    } catch (err) {
      return errorHandling(err);
    }
  }
}

export const STORE_TRANSACTION = "transactionStore";
