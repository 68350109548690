import { ethers } from "ethers";
import BigNumber from "bignumber.js";

export const parseBnNumberValue = (value, decimals) => {
  return new BigNumber(value).shiftedBy(-decimals).toNumber();
};

export const formatAmountToBnString = (value, dp) => {
  if (!value || !dp) return false;

  const formattedValue = ethers.utils.parseUnits(value, dp);
  return formattedValue;
};
