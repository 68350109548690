import { BigNumber } from "ethers";
import React, { useState } from "react";
import {
  openErrorNotification,
  openWarningNotification,
  ReverseButton,
} from "src/components";
import { history } from "src/stores";
import { formatAmountToBnString } from "src/config/crypto";
import {
  useContract,
  useProvider,
  useContractWrite,
  useContractRead,
  useSwitchNetwork,
  useDisconnect,
  useSendTransaction,
} from "wagmi";
import {
  acceptToken,
  cryptoConfig,
  getChain,
  getCurrency,
  getTokenPair,
  validate,
} from "src/config";
import { t } from "i18next";
import ercTokenABI from "../../config/abi/erc20Token.json";
import { useWeb3Modal } from "@web3modal/react";

export const ActionButton = (props: any) => {
  const {
    chain,
    networkName,
    transaction = {},
    userWallet,
    tokenBalance,
    selectedToken = {},
    createPaymentOrder,
    getLatestTokenPrice,
  } = props;

  let receiver: any = cryptoConfig.receiverWalletAddress;
  const provider = useProvider();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();

  const [isProcessing, setIsProcessing] = useState(false);
  const [params, setParams] = useState<any>({
    bnHex: "0",
    usd: "0",
  });
  const { id, network }: any = chain;
  const { switchNetwork }: any = useSwitchNetwork();

  //Connect contract to get signerOrProvider
  useContract({
    address: selectedToken.address, //e.g. ETH, USDT
    abi: ercTokenABI,
    signerOrProvider: provider,
  });

  const { data: allowance } = useContractRead({
    address: selectedToken.address,
    abi: ercTokenABI,
    functionName: "allowance",
    args: [userWallet, selectedToken.address],
  });

  // Action: Transfer (Non-native Token)
  const { write: transfer }: any = useContractWrite({
    address: selectedToken.address,
    abi: ercTokenABI,
    functionName: "transfer",
    args: [receiver, BigNumber.from(params.bnHex)],
    mode: "recklesslyUnprepared",
    onSuccess: (data: any) => {
      // 8. Create order
      if (!data || !data?.hash) return;
      createPendingOrder(data);
    },
    onError: (data: any) => {
      setIsProcessing(false);
      return openWarningNotification(
        `${t(`notification.warning.transferCancelled`)}`
      );
    },
  });

  // Action: Approve
  const { write: approve } = useContractWrite({
    address: selectedToken.address,
    abi: ercTokenABI,
    functionName: "approve",
    args: [selectedToken.address, BigNumber.from(params.bnHex)],
    mode: "recklesslyUnprepared",
    onSuccess: (data: any) => {
      transfer?.({
        recklesslySetUnpreparedArgs: [receiver, BigNumber.from(params.bnHex)],
      });
      setIsProcessing(false);
    },
    onError: (data: any) => {
      setIsProcessing(false);
      return openWarningNotification(
        `${t(`notification.warning.transferCancelled`)}`
      );
    },
  });

  // Action: Send Transaction (Native Token)
  const { sendTransaction } = useSendTransaction({
    mode: "recklesslyUnprepared",
    request: {
      to: receiver,
      value: BigNumber.from(params.bnHex),
    },
    onSuccess: (data: any) => {
      // 8. Create order
      if (!data || !data?.hash) return;
      createPendingOrder(data);
    },
    onError: (data: any) => {
      setIsProcessing(false);
    },
  });

  const createPendingOrder = (data: any) => {
    const payload = {
      ...transaction,
      method: "crypto",
      currency: getCurrency(network, selectedToken.name.toUpperCase()),
      amount: params.usd,
      txHash: data?.hash,
    };
    createPaymentOrder(payload).then((order: any) => {
      if (!order) return;
      return history.push(`/success?orderId=${data?.hash}&method=crypto`);
    });
  };

  const handleOnSubmit = async (selectedToken: any) => {
    // Check items
    if (transaction && transaction.checkout_items.length === 0) return;

    // Check whether there is allowance
    if (!allowance && selectedToken && selectedToken.address) {
      disconnect();
      open();
      return openErrorNotification(`${t(`messages.pleaseReconnectWallet`)}`);
    }

    // Check whether the selected network is supported
    const { chainId }: any = getChain(networkName);
    if (chain.id !== chainId) {
      switchNetwork(chainId);
      return openErrorNotification(
        `${t(`notification.error.notSupportedPaymentNetwork`)}`
      );
    }

    if (!selectedToken || !selectedToken.name) return;

    if (!userWallet)
      return openErrorNotification(`${t(`messages.pleaseReconnectWallet`)}`);
    try {
      setIsProcessing(true);
      // Check Available Coupons with couponId
      for (let item of transaction["checkout_items"]) {
        let stockCount = item["available_stocks"];
        if (!stockCount) {
          setIsProcessing(false);
          openWarningNotification(t("messages.couponOutOfStock"));
          return history.push("/checkout");
        }
      }

      // 1. Vaildate token for payment
      if (acceptToken.indexOf(selectedToken.name) < 0) {
        setIsProcessing(false);
        return openErrorNotification(
          `${t(`notification.error.invaildPaymentToken`)}`
        );
      }
      // 2. Check if selected network === chain id
      const isValidated = validate(id, selectedToken, network, t);
      if (!isValidated) return;

      const tokenPair: any = getTokenPair(selectedToken.name, network);
      const transactionAmount = await getLatestTokenPrice({
        amount: transaction.amount,
        tokenPair: tokenPair,
      });
      const { usd }: any = transactionAmount;
      let tokenInUsdPrice = Number(usd).toFixed(4);
      const formattedBnString: any = formatAmountToBnString(
        tokenInUsdPrice,
        selectedToken.dp
      );
      if (!formattedBnString) {
        setIsProcessing(false);
        return openErrorNotification(`${t(`notification.warning.disable`)}`);
      }
      setParams({ bnHex: formattedBnString, usd: tokenInUsdPrice });

      // 3. Check user balance (Balance >= Amount)
      if (Number(tokenBalance) < Number(tokenInUsdPrice)) {
        setIsProcessing(false);
        return openErrorNotification(
          `${t(`notification.warning.insufficientBalance`)}`
        );
      }

      if (selectedToken && !selectedToken.address) {
        //Native token
        return sendTransaction?.({
          recklesslySetUnpreparedRequest: {
            to: receiver,
            value: BigNumber.from(formattedBnString),
          },
        });
      }
      // 4. Check Allowance for non native tokens and get spender approval
      if (
        Number(allowance) === 0 ||
        Number(allowance) < Number(tokenInUsdPrice)
      ) {
        return approve?.({
          recklesslySetUnpreparedArgs: [
            selectedToken.address,
            BigNumber.from(formattedBnString),
          ],
        });
      }
      transfer?.({
        recklesslySetUnpreparedArgs: [
          receiver,
          BigNumber.from(formattedBnString),
        ],
      });
    } catch (err) {
      setIsProcessing(false);
      return openErrorNotification(`${t(`notification.error.failedToSubmit`)}`);
    }
  };

  return (
    <div>
      <ReverseButton
        isLoading={isProcessing}
        disabled={isProcessing}
        handleAction={() => handleOnSubmit(selectedToken)}
        fullWidth={true}
        buttonText={`button.payWith${selectedToken.name}`}
        customStyle={`px-[5px] py-[8px] sm:text-sm focus:ring-1 sm:text-[12px] tracking-wider mt-3 mb-5`}
      />
    </div>
  );
};
