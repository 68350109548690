import React from "react";
import { Select } from "antd";

export const SelectDropdown = (props: any) => {
  const {
    defaultValue = "",
    handleChange,
    className = "",
    value = "",
    options = [],
    allowClear = false,
    showArrow = false,
    disabled = false,
  } = props;

  return (
    <Select
      showSearch
      defaultValue={defaultValue}
      value={value}
      className={className}
      onChange={handleChange}
      showArrow={showArrow}
      allowClear={allowClear}
      options={options}
      disabled={disabled}
    />
  );
};
