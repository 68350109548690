import React, { useEffect, useState } from "react";
import { history, useCouponStore } from "src/stores";
import { Container } from "src/components";
import { RedeemCoupon } from "./redeem-coupon";

export const UseCoupon = (props: any) => {
  const { match, user } = props;
  const couponId = match.params.id || null;
  const [coupon, setCoupon] = useState<any>({});
  const { getUserCoupons } = useCouponStore();

  useEffect(() => {
    if (!couponId) return history.push("/");

    getUserCoupons(`?couponId=${couponId}`).then((result: any) => {
      if (!result) setCoupon(null);
      const coupon = result.find((item: any) => item["_id"] === couponId);
      coupon && setCoupon([coupon]);
    });
  }, [couponId, getUserCoupons, setCoupon]);

  return (
    <Container>
      <RedeemCoupon coupon={coupon} user={user} />
    </Container>
  );
};
