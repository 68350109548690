import { t } from "i18next";
import React from "react";
import { setLocalStorage } from "../../utils";
import { Empty } from "antd";
import { history } from "src/stores";
import { Product } from "../../components/shopping-cart/product";

export const Products = (props: any) => {
  const { totalPrice, checkoutData, setCheckoutData } = props;

  const updateAmountForProduct = (index: number, isIncreased: boolean) => {
    let currentAmount = checkoutData[index].quantity;

    // //On decrement click & delete the last item
    if (checkoutData[index].quantity === 1 && !isIncreased) {
      const updateProducts = checkoutData.filter(
        (item: any) => item._id !== checkoutData[index]._id
      );
      setCheckoutData(updateProducts);
      setLocalStorage("checkoutItems", updateProducts);
      return history.push("/");
    }

    // //On increment click
    checkoutData[index].quantity = isIncreased
      ? currentAmount + 1
      : currentAmount > 0
      ? currentAmount - 1
      : 0;

    setCheckoutData([...checkoutData]);
    window.dispatchEvent(new Event("storage")); //This is the important part
    // update the localStorage

    const storeItems = checkoutData.map((product: any) => {
      return { _id: product._id, quantity: product.quantity };
    });
    setLocalStorage("checkoutItems", storeItems);
  };

  return checkoutData && checkoutData.length > 0 ? (
    <div className="flex flex-col mx-auto mt-[20px] clear-both shadow-[0_0_8px_-3px_rgba(0,0,0,1)] min-w-[auto] sm:min-w-[451px] min-h-[250px] max-h-[625px]">
      <div className="flex flex-col overflow-auto w-full">
        {checkoutData.map((data: any, index: number) => {
          const { name, amount: price, logo, quantity = 0 } = data;
          return (
            <div key={`checkout-${index}`} className="flex flex-row text-sm">
              <Product
                updateAmountForProduct={(isIncreased: boolean) =>
                  updateAmountForProduct(index, isIncreased)
                }
                name={name}
                amount={quantity}
                price={price}
                logo={logo}
              />
            </div>
          );
        })}
      </div>
      {/* total price for the order */}
      <div className="flex flex-row flex-1 min-h-[80px] text-sm tracking-wider items-center p-3">
        <div className="flex flex-row">
          <p className="mr-2">{t("checkout.totalTitle")}</p>
          <p className="tracking-wider text-[#76C14E]">${totalPrice} HKD</p>
        </div>
      </div>
    </div>
  ) : (
    <Empty />
  );
};
