import React, { useMemo, useState, useEffect } from "react";
import { Container, LoadingIcon } from "src/components";
import { Menu } from "./menu";
import { Coupons } from "./coupons";
import { checkExpireDate } from "../../utils";
import { useCouponStore, useUserStore } from "../../stores";

import { t } from "i18next";
import { RevdBalance } from "./revd";

export const Wallet = (props: any) => {
  const [currentSection, setCurrentSection] = useState(0);
  const [couponData, setCouponData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState<any>(null);
  const { getUserCoupons } = useCouponStore();
  const { getUser } = useUserStore();

  useEffect(() => {
    getUserCoupons().then((result: any) => {
      setLoading(false);
      if (!result) return;
      let userCoupons: any = [];
      for (let item of result) {
        const { coupon_supplies } = item;
        if (coupon_supplies.length === 0) continue;
        const remaining = coupon_supplies.filter(
          (item: any) => !item.is_used
        ).length;
        userCoupons = [...userCoupons, { ...item, remaining }];
      }
      setCouponData(userCoupons);
    });
  }, [getUserCoupons, setCouponData]);

  useEffect(() => {
    getUser().then((result) => {
      if (!result) return;
      setUser(result);
    });
  }, [getUser]);

  const coupons = useMemo(() => {
    if (!currentSection) return couponData;
    let couponList = couponData;
    // 0 = all , 1 = not use, 2, 3 = close to expire, 4 = used, 5 = expired
    switch (currentSection) {
      case 0:
        return couponList;
      case 1:
        return couponList.filter(
          (item: any) =>
            item.remaining > 0 && checkExpireDate(item.expiry_date) === 0
        );
      case 2:
        return couponList.filter(
          (item: any) =>
            item.remaining > 0 && checkExpireDate(item.expiry_date) === 1
        );
      case 3:
        return couponList.filter((item: any) => item.remaining === 0);
      case 4:
        return couponList.filter(
          (item: any) => checkExpireDate(item.expiry_date) === 2
        );
    }
    return couponList;
  }, [currentSection, couponData]);

  return (
    <Container>
      <div className="flex flex-col items-center pb-10">
        <h4 className="pb-5">{t("wallet.title")}</h4>

        <div className="pb-10">
          <RevdBalance user={user} />
        </div>

        <Menu
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
        />
        {loading && (
          <div className="flex w-screen justify-center mt-5">
            <LoadingIcon />
          </div>
        )}

        {!loading && <Coupons couponData={coupons} />}
      </div>
    </Container>
  );
};
