import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import { history } from ".";
import { setToken, removeToken } from "../utils";
import { errorHandling, isResultSuccess } from "./api";

export class AuthStore {
  async getOtpCode(params: any) {
    const url = `${Configs.apiUrl}/auth/get-otp`;
    try {
      const result: any = await axios.post(url, params);
      if (!isResultSuccess(result)) return generalError;
      return result["data"];
    } catch (e) {
      return generalError;
    }
  }

  async access(payload: any) {
    const url = `${Configs.apiUrl}/auth/access`;
    try {
      const result: any = await axios.post(url, payload);
      if (!isResultSuccess(result)) return generalError;
      const jwt = result["data"]["data"]["jwt"];
      setToken(jwt);
      return { success: true };
    } catch (e) {
      removeToken();
      return errorHandling(e);
    }
  }
  logout() {
    removeToken();
    history.push("/login");
    return;
  }
}

export const STORE_AUTH = "authStore";
