import React from "react";
import { t } from "i18next";
export const Product = (props: any) => {
  const { price, amount, logo, name, updateAmountForProduct } = props;

  return (
    <div className="flex flex-col sm:!flex-row text-sm border-b-[#76C14E] border-b-4 p-4 w-full">
      {/* Coupon Icon */}
      <div className="p-2.5 border border-primary rounded-[65px] overflow-hidden w-fit m-auto">
        <img
          src={logo || "/assets/icons/default-coupon.jpeg"}
          className="object-contain w-[60px] h-[60px]"
          alt="coupon-img"
        />
      </div>

      {/* checkout details */}
      <div className="flex-1 flex flex-col md:py-2 py-4 px-3">
        <p className="flex-1 tracking-wide text-sm">{name}</p>
        {/* total price */}
        <div className="flex flex-col sm:!flex-row">
          <div className="flex flex-row flex-1 pt-5 sm:pt-1">
            <p className="mr-2">{t("checkout.totalTitle")}</p>
            <p className="tracking-wide text-[#76C14E]">
              ${price * amount} HKD
            </p>
          </div>
          {/* amount */}
          <div className="flex flex-row pt-5 sm:pt-0 items-center">
            <p
              className="mr-2 cursor-pointer p-1"
              onClick={() => updateAmountForProduct(false)}
            >
              -
            </p>
            <p className="self-end mb-0 mr-2 min-w-[40px] text-center p-1">
              {amount}
            </p>
            <p
              className="mr-2 cursor-pointer p-1"
              onClick={() => updateAmountForProduct(true)}
            >
              +
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
