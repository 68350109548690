import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import {
  errorHandling,
  isResultSuccess,
  refreshNewToken,
  setHeader,
} from "./api";

export class CouponStore {
  async getCoupon(couponId: string = "") {
    const url = `${Configs.apiUrl}/coupons/get-one${couponId}`;
    try {
      const result: any = await axios.get(url);
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getCoupons(args?: any) {
    const url = `${Configs.apiUrl}/coupons/get${args ? args : ""}`;
    try {
      const result: any = await axios.get(url);
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getUserCoupons(couponId: string = "") {
    const url = `${Configs.apiUrl}/user/coupons${couponId}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async redeemCoupon(payload: any) {
    const url = `${Configs.apiUrl}/coupons/redeem-coupon`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_COUPON = "couponStore";
