import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Container, LoadingIcon, openErrorNotification } from "src/components";
import { history, useTransactionStore } from "src/stores";
import { setLocalStorage } from "src/utils";

export const Status = (props: any) => {
  const { updatePaymentOrder } = useTransactionStore();
  const search = useLocation().search;
  const orderId = new URLSearchParams(search).get("orderId");
  const [loading, setLoading] = useState(false);

  // Update Payment Success & Issue Coupon
  const updateStripePayment = useCallback(
    async (id: string) => {
      const payment = await updatePaymentOrder({
        orderId: id,
        status: "succeed",
      });
      setLoading(false);
      if (!payment) {
        openErrorNotification(t("payment.error.approveOrderError"));
        return history.push("/error");
      }
      switch (payment["status"]) {
        case "succeed":
          //Clear local storage if payment success
          setLocalStorage("checkoutItems", []);
          return history.push("/success");
        default:
          openErrorNotification(t("payment.error.approveOrderError"));
          return history.push("/");
      }
    },
    [updatePaymentOrder]
  );

  useEffect(() => {
    if (!orderId) return;
    updateStripePayment(orderId);
  }, [orderId, updateStripePayment]);

  return (
    <Container>
      <div className="flex flex-1 justify-center items-center h-screen">
        {loading && <LoadingIcon />}
      </div>
    </Container>
  );
};
