import React from "react";
import {
  LineShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WeiboShareButton,
  RedditShareButton,
} from "react-share";

import {
  LineIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WeiboIcon,
  WhatsappIcon,
} from "react-share";

export const SocialShareBtns = (props: any) => {
  const { title } = props;

  const URL = window.location.href;
  const ICON_SIZE = 32;

  return (
    <div className="flex flex-row mb-4">
      {/* Whatsapp */}
      <WhatsappShareButton
        title={title}
        url={URL}
        className="social-btn"
        children={<WhatsappIcon size={ICON_SIZE} round={true} />}
      />
      {/* Telegram */}
      <TelegramShareButton
        title={title}
        url={URL}
        className="social-btn ml-3"
        children={<TelegramIcon size={ICON_SIZE} round={true} />}
      />
      {/* Twitter */}
      <TwitterShareButton
        title={title}
        url={URL}
        hashtags={[
          "REVD",
          "token",
          "BUIDL",
          "Buy2Earn",
          "blockchain",
          "crypto",
          "DAO",
          "voucher",
          "rewards",
          "marketplace",
          "web3",
        ]}
        className="social-btn ml-3"
        children={<TwitterIcon size={ICON_SIZE} round={true} />}
      />
      {/* Line */}
      <LineShareButton
        title={title}
        url={URL}
        className="social-btn ml-3"
        children={<LineIcon size={ICON_SIZE} round={true} />}
      />
      {/* Weibo */}
      <WeiboShareButton
        title={title}
        url={URL}
        className="social-btn ml-3"
        children={<WeiboIcon size={ICON_SIZE} round={true} />}
      />
      {/* Reddit */}
      <RedditShareButton
        title={title}
        url={URL}
        className="social-btn ml-3"
        children={<RedditIcon size={ICON_SIZE} round={true} />}
      />
    </div>
  );
};
