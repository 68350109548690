import { t } from "i18next";
import React from "react";
import { CouponTnC, HorizontalCoupons, SocialShareBtns } from "src/components";
import { formatDollar } from "src/utils";

export const CouponData = (props: any) => {
  const { coupon } = props;

  if (!coupon || coupon.length === 0) return null;
  let title = "";
  return (
    <div className="flex flex-col pt-20">
      {coupon.length &&
        coupon.map((item: any, i: number) => {
          title =
            parseInt(item.face_value) === parseInt(item.amount)
              ? t(`noDiscountTextWithoutReferral`, {
                  amount: formatDollar(item.amount, "HKD"),
                  name: item.name,
                })
              : t(`showDiscountTextWithoutReferral`, {
                  amount: formatDollar(item.amount, "HKD"),
                  face_value: formatDollar(item.face_value, "HKD"),
                  name: item.name,
                });

          return <SocialShareBtns key={i} title={title || ""} />;
        })}
      <HorizontalCoupons couponData={coupon} fromPage="coupon" />
      {coupon && (
        <div className="pt-20">
          <CouponTnC coupon={coupon} />
        </div>
      )}
    </div>
  );
};
