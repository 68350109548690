import React, { useState } from "react";
import { t } from "i18next";
import { Space } from "antd";
import { history, useAuthStore } from "src/stores";
const { Dropdown, Menu } = require("antd");

export const MenuDropdown = (props: any) => {
  const { children, isLoggedIn } = props;
  const [open, setOpen] = useState(false);
  const { logout } = useAuthStore();

  const handleMenuClick = (e: any) => {
    if (!e && !e.key) return;
    switch (e.key) {
      case "0":
        history.push("/login");
        break;
      case "1":
        history.push("/profile");
        break;
      case "2":
        history.push("/referral");
        break;
      case "3":
        logout();
        break;
      default:
        break;
    }
    setOpen(false);
  };

  const notLoggedInMenu = [
    {
      key: "0",
      label: t(`header.login`),
      className: "text-[10px]",
    },
  ];

  const menuItems = [
    {
      key: "1",
      label: t(`header.editProfile`),
      className: "text-[10px] tracking-wide",
    },
    {
      key: "2",
      label: t(`header.referral`),
      className: "text-[10px]",
    },
    {
      key: "3",
      label: t(`header.logout`),
      className: "text-[10px]",
    },
  ];

  const menu = (
    <Menu
      selectedKeys={["sortByLatest"]}
      onClick={handleMenuClick}
      items={isLoggedIn ? menuItems : notLoggedInMenu}
    />
  );

  const handleVisibleChange = (flag: boolean) => {
    setOpen(flag);
  };

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      onOpenChange={handleVisibleChange}
      open={open}
      placement="bottomRight"
    >
      <Space>{children}</Space>
    </Dropdown>
  );
};
