import axios from "axios";
import { generalError } from ".";
import * as Configs from "../config";
import {
  errorHandling,
  isResultSuccess,
  refreshNewToken,
  setHeader,
} from "./api";

export class UserStore {
  async getUser(handleErr = true) {
    const url = `${Configs.apiUrl}/user/get`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
    } catch (e) {
      return handleErr ? errorHandling(e) : false;
    }
  }
  async updateUser(payload: any) {
    const url = `${Configs.apiUrl}/user/update-profile`;
    try {
      const result: any = await axios.put(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  // Red Pocket
  async checkRedPocket(handleErr = true) {
    const url = `${Configs.apiUrl}/red-pocket/check`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"]["is_recevied"];
    } catch (e) {
      return handleErr ? errorHandling(e) : false;
    }
  }

  // Red Pocket
  async openRedPocket() {
    const url = `${Configs.apiUrl}/red-pocket/open`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"]["reward"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_USER = "userStore";
