import moment from "moment";
export const padZerosBehind = (amount: any, dp: number) => {
  return String(amount * 10 ** dp);
};

export const formatDollar = (amount: any, currency: string) => {
  amount = parseFloat(amount);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currencyDisplay: "code",
    currency: currency || "USD",
  });
  return `$${formatter
    .format(amount)
    .replace(currency, "")
    .trim()} ${currency}`;
};

export const numberCommaFormatted = (number: any) => {
  const formatted = number.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
  return formatted;
};

export const formatDate = (date: string) => {
  return moment(date).format("DD MMM yyyy");
};

export const getLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : [];
};

export const setLocalStorage = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};
