import { t } from "i18next";
import React from "react";

const menuStyle = `mb-5 max-md:mr-10 font-bold text-sm tracking-wide cursor-pointer max-w-fit`;

export const Footer = (props: any) => {
  const redirect = (url: string, type = "_blank") => {
    if (!url) return;
    return window.open(url, type);
  };

  return (
    <div className="flex flex-col relative bottom-0 ">
      {/* breaking line */}
      <div className="h-[1px] bg-[#F3F3F3] md:mb-20 max-md:mb-10" />
      <div className="flex max-md:flex-col md:flex-row max-md:px-5">
        {/* left section */}
        <div className="flex flex-col flex-1 max-md:mb-5">
          <p className="mb-10 font-bold text-[12px] tracking-wide">
            {t(`footer.copyright`)}
          </p>
          {/* footer menu */}
          <div className="max-md:flex max-md:flex-row">
            <p
              className={menuStyle}
              onClick={() => redirect("https://reversedao.io/aboutus")}
            >
              {t(`footer.aboutUs`)}
            </p>
            <p
              className={menuStyle}
              onClick={() =>
                redirect(
                  "https://d2kblyb2mozuv3.cloudfront.net/reverse_pitch_deck.pdf"
                )
              }
            >
              {t(`footer.whitepaper`)}
            </p>
            <p
              className={menuStyle}
              onClick={() => redirect("https://reversedao.io/news")}
            >
              {t(`footer.news`)}
            </p>
          </div>
        </div>

        {/* right section */}
        <div className="flex flex-col flex-1 max-md:mb-5">
          <p className="mb-10 font-bold text-sm tracking-widest text-right">
            {t(`footer.followUs`)}
          </p>
          {/* icons */}
          <div className="flex flex-row max-md:self-start md:self-end">
            <img
              className="mr-10 cursor-pointer"
              src="/assets/icons/tr.png"
              alt="twitter"
              onClick={() => redirect("https://twitter.com/REVDReverse")}
            />
            <img
              className="mr-10 cursor-pointer"
              src="/assets/icons/ig.png"
              alt="ig"
              onClick={() =>
                redirect("https://www.instagram.com/reversedao_official/")
              }
            />
            <img
              className="mr-3 cursor-pointer"
              src="/assets/icons/fb.png"
              alt="fb"
              onClick={() => redirect("https://www.facebook.com/RDAOreverse/")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
