import React, { useEffect } from "react";
import { t } from "i18next";
import { useLocation } from "react-router";
import {
  Container,
  openSuccessNotification,
  ReverseButton,
} from "src/components";
import { history } from "src/stores";
import { setLocalStorage } from "src/utils";

export const Success = (props: any) => {
  const search = useLocation().search;
  const orderId = new URLSearchParams(search).get("orderId");
  const method = new URLSearchParams(search).get("method");

  // // Check Payment Status
  useEffect(() => {
    if (!orderId) return;
    if (method !== "crypto") return;
    setLocalStorage("checkoutItems", []);
    openSuccessNotification(`${t(`notification.success.emailNotification`)}`);
  }, [orderId, method]);

  return (
    <Container>
      <div className="clear-both m-auto mt-[60px] w-[auto] md:w-[480px] text-center px-[20px]">
        <div className="flex flex-col items-center">
          <img
            src="assets/success_icon.png"
            alt="issue-coupon-success"
            className="object-contain w-[60px] h-[60px]"
          />
          <h3 className="mt-xl mb-xl text-[16px] text-black tracking-[20px] pl-[20px]">
            {t("status.success")}
          </h3>
          {/* Email Message */}
          <p className="mb-xl text-[12px] tracking-[0px]">
            {t("status.email")}
          </p>

          <ReverseButton
            handleAction={() => history.push("/wallet")}
            buttonText={`button.goToWallet`}
            customStyle={`w-[250px] rounded mb-4 py-[8px] sm:text-sm focus:ring-1 sm:text-[12px] tracking-wider text-white bg-primary`}
          />

          <ReverseButton
            handleAction={() => history.push("/")}
            buttonText={`button.goToHome`}
            customStyle={`w-[250px] rounded py-[8px] sm:text-sm focus:ring-1 sm:text-[12px] tracking-wider text-primary bg-white border border-primary`}
          />
        </div>
      </div>
    </Container>
  );
};
