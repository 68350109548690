import React, { useEffect, useState } from "react";
import { useUserStore } from "src/stores";
import { hasLogin } from "src/utils";
import { Footer } from "../footer";
import { Header } from "../header";

export const Container = (props: any) => {
  const { children } = props;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({
    phone_country: "",
    phone_num: "",
    email: "",
    nickname: "",
    referral_code: "",
    avatar: "",
  });
  const { getUser } = useUserStore();

  useEffect(() => {
    let accessToken: any = hasLogin();
    if (!accessToken) return setIsLoggedIn(false);

    getUser().then((result: any) => {
      if (!result) return;
      setUser(result);
      setIsLoggedIn(true);
    });
  }, [getUser, setUser, setIsLoggedIn]);

  return (
    <div>
      <Header isLoggedIn={isLoggedIn} user={user} />
      <div className="container min-h-screen w-screen mx-auto bg-alt-bg sm:px-10 px-5 pt-10 pb-10">
        {children}
        <div className="pt-10" />
        <Footer />
      </div>
    </div>
  );
};
