import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  openSuccessNotification,
  openErrorNotification,
} from "src/components";
import { ReferralForm } from "./referralForm";
import { t } from "i18next";
import { useUserStore, useReferralStore } from "../../stores";

const promoTexts = [t("referral.promo1"), t("referral.promo2")];

export const Referral = (props: any) => {
  const [referralCode, setReferralCode] = useState("");
  const [selfReferralCode, setSelfReferralCode] = useState("");
  const [usedReferralCode, setUsedReferralCode] = useState(false);
  const [appliedCode, setAppliedCode] = useState(false);

  const { getUser } = useUserStore();
  const { applyReferralCode, checkReferralStatus } = useReferralStore();

  useEffect(() => {
    getUser().then((result) => {
      if (result && result.referral_code)
        setSelfReferralCode(result.referral_code);
    });
  }, [getUser]);

  useEffect(() => {
    checkReferralStatus().then((result) => {
      const { appliedCode, usedReferralCode } = result;
      setAppliedCode(appliedCode);
      setUsedReferralCode(usedReferralCode);
    });
  }, [setAppliedCode, setUsedReferralCode, checkReferralStatus]);

  const promotion = useMemo(() => {
    return promoTexts.map((promoText, index) => {
      return (
        <p
          key={`benefit${index}`}
          className="mt-5 tracking-wider text-[14px]	text-[#6F6F6F]"
        >
          {index + 1}. {promoText}
        </p>
      );
    });
  }, []);

  const handleReferral = () => {
    if (referralCode.length === 0) {
      openErrorNotification(t("referral.form.error.empty"));
      return;
    }

    if (referralCode === selfReferralCode) {
      openErrorNotification(t("referral.form.error.self"));
      return;
    }

    applyReferralCode(referralCode).then((result) => {
      if (result && !result.success) {
        const { error } = result;
        switch (error) {
          case "referral_record_exist":
            openErrorNotification(t("referral.errors.used"));
            break;
          default:
            openErrorNotification(t("referral.errors.default"));
            break;
        }
      }
      // Success Case
      setAppliedCode(true);
      return openSuccessNotification(t("referral.form.success"));
    });
  };

  return (
    <Container>
      <div className="flex flex-col items-center">
        <h5 className="mb-10 tracking-widest text-lg ml-10">
          {t("referral.title")}
        </h5>
        <ReferralForm
          referralCode={referralCode}
          setReferralCode={setReferralCode}
          handleReferral={handleReferral}
          selfReferralCode={selfReferralCode}
          usedReferralCode={usedReferralCode}
          appliedCode={appliedCode}
        />
        <h5 className="mt-20 tracking-widest text-lg ml-10">
          {t("referral.subtitle")}
        </h5>

        <div className="pt-5 px-5 w-[auto] md:w-[600px]">{promotion}</div>
      </div>
    </Container>
  );
};
