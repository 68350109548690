import React from "react";
import { EventCarousel } from "src/components/event-carousel";

export const EventSection = (props: any) => {
  return (
    <div>
      <EventCarousel {...props} />
    </div>
  );
};
