import { t } from "i18next";
import React from "react";

export const RevdBalance = (props: any) => {
  const { user } = props;

  return (
    <div className="drop-shadow-2xl rounded-2xl w-[300px] min-h-[100px] bg-gradient-to-r from-primary to-secondary mt-5 p-5 py-3 text-left pb-8">
      <p className="text-white text-xs tracking-[10px]">
        {t("wallet.balance")}
      </p>
      <p className="text-white text-3xl mt-1 font-light">
        {" "}
        {user ? user.reverse_points : 0}
      </p>
    </div>
  );
};
