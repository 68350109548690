import React, { useMemo, useState } from "react";
import { t } from "i18next";
import { history } from "src/stores";
import { ShoppingCart } from "../shopping-cart";
import { MenuDropdown } from "..";
import { RandomAvatar } from "../random-avatar";
import { SideMenu } from "./sideMenu";
import { openWarningNotification } from "src/components";

const menuTextStyle =
  "font-bold text-[12px] tracking-wide cursor-pointer mr-10 !mb-0";
const avatarStyle = "h-10 w-10 rounded-full ring-2 ring-white cursor-pointer";
const iconStyle = "h-7 w-7 cursor-pointer";
const iconDivStyle =
  "flex flex-row justify-center mr-10 overflow-hidden rounded-full drop-shadow-2xl";

export const Header = (props: any) => {
  const { isLoggedIn, user } = props;
  const [showShoppingCart, setShowShoppingCart] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { customStyles = "" } = props;

  const logoRender = useMemo(() => {
    return (
      <div
        className="flex flex-col flex-1 cursor-pointer"
        onClick={() => {
          history.push("/");
        }}
      >
        <img className="w-36" src="/assets/icons/logo.png" alt="Reverse" />
      </div>
    );
  }, []);

  const desktopMenuRender = useMemo(() => {
    return (
      <div className="flex flex-row items-center">
        <div className="flex flex-row items-center cursor-pointer">
          <img
            className="w-5 h-5 mr-2"
            src="/assets/icons/earth.png"
            alt="hk"
          />
          <p className={menuTextStyle}>{t(`header.hk`)}</p>
        </div>
        <p onClick={() => history.push("/wallet")} className={menuTextStyle}>
          {t(`header.wallet`)}
        </p>

        {/* Avatar Menu Dropdown */}
        <div className={iconDivStyle}>
          <MenuDropdown isLoggedIn={isLoggedIn}>
            {/* Default Login Icon */}
            {!isLoggedIn && (
              <img
                className={avatarStyle}
                src={`/assets/defaultAvatar.png`}
                alt="avatar"
              />
            )}

            {/* Login Icon */}
            {isLoggedIn && !user.avatar && (
              <div className="cursor-pointer">
                <RandomAvatar user={user} size={35} />
              </div>
            )}
          </MenuDropdown>
        </div>
        <div
          className={`${iconDivStyle} mr-0 rounded-[0px]`}
          onClick={() => setShowShoppingCart(!showShoppingCart)}
        >
          <img
            className={iconStyle}
            src="/assets/icons/shopping-cart.png"
            alt="check shopping cart"
          />
        </div>
        {showShoppingCart && (
          <ShoppingCart
            showShoppingCart={showShoppingCart}
            setShowShoppingCart={setShowShoppingCart}
            user={user}
          />
        )}
      </div>
    );
  }, [user, isLoggedIn, showShoppingCart, setShowShoppingCart]);

  return (
    <React.Fragment>
      <SideMenu
        isOpen={isOpen}
        isLoggedIn={isLoggedIn}
        setIsOpen={setIsOpen}
        showShoppingCart={showShoppingCart}
      />
      <div
        className={`sticky top-0 z-30 bg-white ${customStyles} flex flex-col max-md:py-2 max-md:px-2 md:py-5 md:px-10`}
      >
        {/* mobile menu */}
        <div className="max-md:flex max-md:flex-row md:hidden items-center">
          {logoRender}
          {/* Checkout shopping char */}
          <div
            onClick={() => {
              if (!isLoggedIn)
                return openWarningNotification(
                  t("messages.pleaseLoginToContinue")
                );
              return history.push("/checkout");
            }}
          >
            <img
              className={"h-6 w-6 self-center"}
              src="/assets/icons/shopping-cart.png"
              alt="check shopping cart"
            />
          </div>
          <div
            className="flex justify-end pl-5"
            onClick={() => setIsOpen(true)}
          >
            <img
              className="h-8 w-8 self-center cursor-pointer"
              src="/assets/icons/menu.png"
              alt=""
            />
          </div>
        </div>

        {/* desktop */}
        <div className="max-md:hidden md:flex-row md:flex">
          {/* left section */}
          {logoRender}
          {/* right section */}
          {desktopMenuRender}
        </div>
      </div>
    </React.Fragment>
  );
};
