import React from "react";
import { Container } from "src/components";
import { EditProfile } from "./edit-profile";

export const Profile = (props: any) => {
  return (
    <Container>
      <EditProfile {...props} />
    </Container>
  );
};
