import { createContext, useContext } from "react";
import { STORE_ROUTER, RouterStore, history } from "./router";
import { STORE_USER, UserStore } from "./user";
import { STORE_COUPON, CouponStore } from "./coupon";
import { STORE_AUTH, AuthStore } from "./auth";
import { STORE_TRANSACTION, TransactionStore } from "./transaction";
import { STORE_REFERRAL, ReferralStore } from "./referral";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_TRANSACTION]: new TransactionStore(),
    [STORE_USER]: new UserStore(),
    [STORE_COUPON]: new CouponStore(),
    [STORE_AUTH]: new AuthStore(),
    [STORE_REFERRAL]: new ReferralStore(),
  };
}

const stores = createStores();

const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

function useTransactionStore() {
  const { transactionStore } = useStores();
  return transactionStore;
}

function useUserStore() {
  const { userStore } = useStores();
  return userStore;
}

function useCouponStore() {
  const { couponStore } = useStores();
  return couponStore;
}

function useAuthStore() {
  const { authStore } = useStores();
  return authStore;
}

function useReferralStore() {
  const { referralStore } = useStores();
  return referralStore;
}

const generalError = { success: false, error: "general_error" };

export {
  stores,
  StoresContext,
  generalError,
  history,
  useRouterStore,
  useUserStore,
  useCouponStore,
  useAuthStore,
  useTransactionStore,
  useReferralStore,
};
