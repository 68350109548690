import React from "react";
import { t } from "i18next";
import { ReverseButton } from "../../components";
const inputStyle =
  "font-bold text-black mt-5 block w-full px-3 py-2 bg-white border text-sm placeholder-black border-[#76C14E] focus:outline-none";

const twoFaBtnStyle =
  "absolute top-[1px] right-0 pl-8 py-2 text-sm font-bold bg-[#76C14E] border-[#76C14E]";

export const TwoFaCodeInput = (props: any) => {
  const { twoFaCode, setTwoFaCode, handleTwoFaCode } = props;

  return (
    <div className="relative w-full">
      <input
        type="search"
        id="search-dropdown"
        className={`mt-5 ${inputStyle}`}
        placeholder={t("login.mobileForm.twoFa")}
        value={twoFaCode}
        onChange={(e) => setTwoFaCode(e.target.value)}
        required
        maxLength={6}
      />
      <ReverseButton
        handleAction={() => handleTwoFaCode()}
        customStyle={twoFaBtnStyle}
        buttonText={"login.mobileForm.twoFaBtnName"}
      />
    </div>
  );
};
