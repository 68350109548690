import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { RandomAvatar } from "src/components/random-avatar";
import {
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
  ReverseButton,
  SelectDropdown,
} from "src/components";
import { countCodeList } from "src/sample-data/country-code";
import { useUserStore } from "src/stores";
import { validateEmail } from "src/utils";

export const EditProfile = (props: any) => {
  const { isLogin, user } = props;
  const inputClass =
    "w-[390px] px-3 py-2 text-black font-black drop-shadow-sm bg-white border shadow-sm border-black placeholder-black focus:outline-none focus:border-primary focus:ring-primary block rounded-sm sm:text-[12px] focus:ring-1";
  const [username, setUsername] = useState("");
  const [phoneCountry, setPhoneCountry] = useState(t(`profile.countryCode`));
  const [countryCodeList, setCountryCodeList] = useState(countCodeList);
  const [phoneNum, setPhoneNum] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { updateUser } = useUserStore();

  useEffect(() => {
    if (!user) return;
    setUsername(user.nickname);
    setPhoneCountry(user.phone_country);
    setPhoneNum(user.phone_num);
    setEmail(user.email);
  }, [user, setUsername, setPhoneCountry, setPhoneNum, setEmail]);

  useEffect(() => {
    if (countCodeList) setCountryCodeList(countCodeList);
  }, [setCountryCodeList]);

  const handleInput = (e: any) => {
    const type = e.target.name;
    const value = e.target.value;
    if (type === "user_name") return setUsername(value);
    if (type === "phone_number") return setPhoneNum(value);
    if (type === "email") return setEmail(value);
  };

  const handleChange = (value: any, options: any) => {
    setPhoneCountry(options.label);
  };

  const handleUpdateProfile = async () => {
    try {
      if (!validateEmail(email))
        return openWarningNotification(t("messages.incorrectEmailFormat"));

      if (username === "" || phoneNum === "" || email === "")
        return openWarningNotification(t("messages.requiredFieldMissing"));

      setLoading(true);
      let body: any = {
        nickname: username,
        phone_country: phoneCountry,
        phone_num: phoneNum,
        email: email,
      };
      const result: any = await updateUser(body);
      setLoading(false);

      if (!result) {
        return openErrorNotification(t("messages.updateProfiletFailed"));
      }
      return openSuccessNotification(t("messages.updateProfileSuccess"));
    } catch (err) {
      setLoading(false);
      return openErrorNotification(t("messages.updateProfiletFailed"));
    }
  };
  return (
    <div className="px-8 flex flex-col m-auto w-[380px] justify-center">
      <div className="rounded-full drop-shadow-2xl overflow-hidden w-[75px] h-[75px] m-auto my-6 sm:mx-auto">
        {isLogin && !user.avatar && <RandomAvatar user={user} size={100} />}
      </div>
      <div className="form">
        <div className="flex py-3">
          {/* UserName */}
          <input
            className={inputClass}
            type="text"
            name="user_name"
            value={username}
            placeholder={t("profile.username")}
            onChange={(e) => handleInput(e)}
          />
        </div>

        {/* Select Country Code */}
        <div className="flex flex-row py-3">
          <div className="m-auto text-black font-black bg-white border border-r-0 border-black shadow-sm placeholder-black focus:outline-none focus:border-primary rounded-l-sm rounded-r-none rounded-sm sm:text-[12px]">
            <SelectDropdown
              value={phoneCountry}
              defaultValue={phoneCountry}
              className="w-[70px] text-primary"
              handleChange={handleChange}
              options={countryCodeList}
              showArrow={false}
            />
          </div>
          <input
            className={
              "min-w-[245px] text-black font-black bg-white px-4 border border-l-0 focus:border-l-2 border-black placeholder-black block rounded-r-sm focus:rounded-sm focus:outline-none focus:border-primary focus:ring-primary sm:text-[12px] focus:ring-1"
            }
            type="number"
            name="phone_number"
            value={phoneNum}
            placeholder={t("profile.phoneNumber")}
            onChange={(e) => handleInput(e)}
          />
        </div>

        {/* Email */}
        <div className="flex py-3">
          <input
            autoComplete="off"
            className={inputClass}
            type="text"
            name="email"
            value={email}
            placeholder={t("profile.email")}
            onChange={(e) => handleInput(e)}
          />
        </div>

        <div className="mt-4">
          <ReverseButton
            handleAction={() => handleUpdateProfile()}
            fullWidth={true}
            buttonText={`button.update`}
            customStyle={`pl-0 px-[5px] py-[8px] sm:text-sm focus:ring-1 sm:text-[12px] tracking-wider`}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
};
