import React from "react";
import { t } from "i18next";

const menuItems = [
  t("wallet.menus.all"),
  t("wallet.menus.notUse"),
  t("wallet.menus.almostExpire"),
  t("wallet.menus.used"),
  t("wallet.menus.expired"),
];

export const Menu = (props: any) => {
  const { currentSection, setCurrentSection } = props;

  return (
    <div className="max-md:w-full max md:w-[50%] flex flex-row items-center text-center">
      {menuItems.map((item, index) => {
        return (
          <p
            key={`menu${index}`}
            onClick={() => setCurrentSection(index)}
            className={`flex-1 text-base sm:text-sm tracking-wider cursor-pointer ${
              index === currentSection &&
              "underline underline-offset-8 decoration-[#76C14E]"
            }`}
          >
            {item}
          </p>
        );
      })}
    </div>
  );
};
