import { t } from "i18next";
import React from "react";
import { VerticalCoupon } from "src/components";
import { Empty } from "antd";

export const AllCoupons = (props: any) => {
  const { coupon } = props;

  return (
    <div className="w-full text-center mt-[50px] mb-[50px]">
      <h4 className="text-black tracking-widest">{t(`home.allItems`)}</h4>
      {coupon && coupon.length === 0 && (
        <div className={`w-full mt-[50px]`}>
          <div className="flex flex-row justify-center">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span className="font-bold tracking-wider text-gray">
                  {t(`home.noItems`)}
                </span>
              }
            />
          </div>
        </div>
      )}

      {coupon.length > 0 && <VerticalCoupon couponData={coupon} />}
    </div>
  );
};
