import { t } from "i18next";
import React from "react";

export const AddToCart = (props: any) => {
  const { addToCart } = props;

  return (
    <h3
      onClick={addToCart}
      className="text-[12px] text-black leading-[12px] cursor-pointer"
    >
      {t(`home.addToCart`)}
    </h3>
  );
};
