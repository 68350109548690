import { t } from "i18next";
import React from "react";
import { CouponTnC, HorizontalCoupons, SocialShareBtns } from "src/components";
import { formatDollar } from "src/utils";

export const RedeemCoupon = (props: any) => {
  const { coupon, user } = props;

  if (!coupon) return null;
  let title = "";
  return (
    <div className="flex flex-col pt-20">
      {coupon.length &&
        coupon.map((item: any, i: number) => {
          title =
            parseInt(item.face_value) === parseInt(item.amount)
              ? t(`noDiscountText`, {
                  amount: formatDollar(item.amount, "HKD"),
                  referral_code: user.referral_code,
                  name: item.name,
                })
              : t(`showDiscountText`, {
                  amount: formatDollar(item.amount, "HKD"),
                  face_value: formatDollar(item.face_value, "HKD"),
                  name: item.name,
                  referral_code: user.referral_code,
                });

          return <SocialShareBtns key={i} title={title || ""} />;
        })}

      <HorizontalCoupons
        couponData={coupon}
        allowRedeem={true}
        fromPage="redeem"
      />
      {coupon && (
        <div className="pt-20">
          <CouponTnC coupon={coupon} />
        </div>
      )}
    </div>
  );
};
