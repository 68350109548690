import React, { useRef, useEffect } from "react";
import { t } from "i18next";
import { history } from "src/stores";
import { ReverseButton } from "..";
import ReactDOM from "react-dom";

const menuTextStyle = "mt-5";

const loggedInMenuItems = [
  {
    path: "profile",
    label: t(`header.editProfile`),
    className: "text-[10px] tracking-wide",
  },
  {
    path: "referral",
    label: t(`header.referral`),
    className: "text-[10px]",
  },
];

export const SideMenu = (props: any) => {
  const { isOpen = false, isLoggedIn, setIsOpen } = props;
  const wrapperRef = useRef(null);

  //   for close the cart when click outside
  useEffect(() => {
    function handleClickOutside(event: any) {
      const domNode = ReactDOM.findDOMNode(wrapperRef.current);

      if (!domNode || !domNode.contains(event.target)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen, wrapperRef]);

  const navigationAction = (page: string) => {
    setIsOpen(false);
    history.push(page || "/");
  };

  return (
    <div
      ref={wrapperRef}
      className={`${
        isOpen ? "translate-x-0 fixed" : "-translate-x-full hidden"
      } transform top-0 right-0 w-[90vw] bg-white  h-full overflow-y-auto ease-in-out transition-all duration-300 z-50 p-2 drop-shadow-2xl`}
    >
      {/* close button */}
      <img
        src={"/assets/icons/close.png"}
        className="w-4 ml-auto mt-5 mr-5"
        alt="avatar"
        onClick={() => setIsOpen(false)}
      />

      {/* Avatar Menu Dropdown */}
      <img
        src={isLoggedIn ? "/assets/5420_pfp.png" : "/assets/defaultAvatar.png"}
        className="w-16 block mr-auto ml-auto mt-10 rounded-full ring-2 ring-white"
        alt="avatar"
      />
      <div className="mt-10 text-lg text-center">
        {/* <p className={menuTextStyle}>{t(`header.explore`)}</p> */}
        <p
          onClick={() => navigationAction("/wallet")}
          className={menuTextStyle}
        >
          {t(`header.wallet`)}
        </p>
        {/* <p className={menuTextStyle}>{t(`header.language`)}</p> */}

        {isLoggedIn && (
          <div className="my-10 border-b-[1px] border-black w-[60%] block mr-auto ml-auto" />
        )}

        {isLoggedIn &&
          loggedInMenuItems.map((menu, index) => {
            const { label, path } = menu;
            return (
              <p
                onClick={() => navigationAction(path)}
                className={menuTextStyle}
                key={index}
              >
                {label}
              </p>
            );
          })}
      </div>

      {/* login button */}
      <ReverseButton
        customStyle={`${
          isLoggedIn && "bg-black"
        } absolute bottom-5 left-0 right-0 ml-auto mr-auto w-52 rounded-lg px-6 py-4`}
        buttonText={isLoggedIn ? "header.logout" : "header.login"}
        handleAction={() => navigationAction("/login")}
      />
    </div>
  );
};
