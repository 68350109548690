import { t } from "i18next";
import React, { useRef, useEffect, useState } from "react";
import { ReverseButton } from "../button";
import { history, useCouponStore } from "src/stores";
import { Product } from "./product";
import ReactDOM from "react-dom";
import { getLocalStorage, setLocalStorage } from "../../utils";
import { Empty } from "antd";
import { openWarningNotification } from "../notification";

const cardStyle =
  "z-50 bg-white right-12 top-14 flex flex-col mx-auto mt-[20px] clear-both shadow-[0_0_8px_-3px_rgba(0,0,0,1)] min-w-[451px] w-[00px] min-h-[300px] max-h-[625px]";

export const ShoppingCart = (props: any) => {
  let totalPrice = 0;
  const [currentProducts, setCurrentProducts] = useState<any[]>([]);
  const [coupons, setCoupons] = useState<any>([]);

  const { showShoppingCart, setShowShoppingCart, user } = props;
  const wrapperRef = useRef(null);

  const { getCoupons } = useCouponStore();

  useEffect(() => {
    const updateCheckoutItems = () => {
      setTimeout(() => {
        let checkoutItems = getLocalStorage("checkoutItems");
        if (checkoutItems.length > 0 && coupons.length > 0) {
          checkoutItems = checkoutItems.map((checkoutItem: any) => {
            const matchItems = coupons.find(
              (item: any) => item["_id"] === checkoutItem["_id"]
            );
            return {
              ...matchItems,
              quantity: checkoutItem["quantity"],
            };
          });
          setCurrentProducts(checkoutItems);
        }
      }, 100);
    };
    window.addEventListener("storage", updateCheckoutItems);
    return () => {
      window.removeEventListener("storage", updateCheckoutItems);
    };
  }, [coupons, setCurrentProducts]);

  //   init the data from the localStorage
  useEffect(() => {
    getCoupons("").then((result) => {
      let checkoutItems = getLocalStorage("checkoutItems");
      if (checkoutItems) {
        checkoutItems = checkoutItems.map((checkoutItem: any) => {
          const matchItems = result.find(
            (item: any) => item["_id"] === checkoutItem["_id"]
          );
          return { ...matchItems, quantity: checkoutItem["quantity"] };
        });
        setCurrentProducts(checkoutItems);
        setCoupons(result);
      }
    });
  }, [getCoupons, setCurrentProducts]);

  //   for close the cart when click outside
  useEffect(() => {
    function handleClickOutside(event: any) {
      const domNode = ReactDOM.findDOMNode(wrapperRef.current);

      if (!domNode || !domNode.contains(event.target)) {
        setShowShoppingCart(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowShoppingCart, wrapperRef]);

  const updateAmountForProduct = (index: number, isIncreased: boolean) => {
    let currentAmount = currentProducts[index].quantity;

    // On decrement click & action for the last item deletion
    if (currentProducts[index].quantity === 1 && !isIncreased) {
      const updateProducts = currentProducts.filter(
        (item: any) => item._id !== currentProducts[index]._id
      );
      window.dispatchEvent(new Event("storage"));
      setCurrentProducts(updateProducts);
      return setLocalStorage("checkoutItems", updateProducts);
    }

    // On increment click
    if (isIncreased) {
      if (
        currentProducts[index].quantity >=
        currentProducts[index].available_stocks
      ) {
        return openWarningNotification(t("messages.couponOutOfStock"));
      }
      currentProducts[index].quantity = currentAmount + 1;
    }

    // Decrement
    if (!isIncreased && currentAmount > 0) {
      currentProducts[index].quantity = currentAmount - 1;
    }

    setCurrentProducts([...currentProducts]);
    window.dispatchEvent(new Event("storage")); //This is the important part
    // update the localStorage

    const storeItems = currentProducts.map((product: any) => {
      return { _id: product._id, quantity: product.quantity };
    });

    setLocalStorage("checkoutItems", storeItems);
  };

  const handleCheckout = () => {
    setShowShoppingCart(false);

    if (currentProducts.length === 0)
      return openWarningNotification(t("messages.cartIsEmpty"));

    if (!user["_id"]) return history.push("/login");

    return history.push("/checkout");
  };

  return (
    <div
      ref={wrapperRef}
      className={`rounded ${cardStyle} ${
        showShoppingCart ? "absolute" : "hidden"
      }`}
    >
      <div className="flex flex-col overflow-auto w-full">
        {currentProducts && currentProducts.length > 0 ? (
          currentProducts.map((data, index) => {
            const { name, amount: price, logo, quantity = 0 } = data;

            totalPrice += quantity * parseFloat(price);

            //TODO: amount > Qty
            return (
              <div key={`cart-${index}`}>
                <Product
                  updateAmountForProduct={(isIncreased: boolean) =>
                    updateAmountForProduct(index, isIncreased)
                  }
                  name={name}
                  amount={quantity}
                  price={price}
                  logo={logo}
                />
              </div>
            );
          })
        ) : (
          <div className="mt-12">
            <Empty />
          </div>
        )}
      </div>
      {/* total price for the order */}
      <div className="flex flex-row flex-1 min-h-[100px] text-sm tracking-wider items-end">
        <div className="flex flex-row flex-1 p-4">
          <p className="mr-2">{t("checkout.totalTitle")}</p>
          <p className="tracking-wider text-[#76C14E]">${totalPrice} HKD</p>
        </div>
        <ReverseButton
          handleAction={() => handleCheckout()}
          customStyle="w-[35%] px-6 py-4"
          buttonText="home.buyNow"
          type="coupon"
        />
      </div>
    </div>
  );
};
