import { t } from "i18next";
import React from "react";

import { HorizontalCoupons } from "src/components/horizontal-coupon";
import { Empty } from "antd";

export const HotCoupon = (props: any) => {
  const { coupon } = props;
  return (
    <div className="w-full text-center mt-[50px] mb-[50px] overflow-hidden">
      <h4 className="text-black tracking-widest">{t(`home.hotItems`)}</h4>

      <div className="mt-[50px]">
        {!coupon.length && (
          <div className={`w-full`}>
            <div className="flex flex-row justify-center">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span className="font-bold tracking-wider text-gray">
                    {t(`home.noHotItems`)}
                  </span>
                }
              />
            </div>
          </div>
        )}

        {coupon.length > 0 && (
          <HorizontalCoupons
            couponData={coupon}
            allowScoller={true}
            fromPage="hot-coupon"
          />
        )}
      </div>
    </div>
  );
};
