import React from "react";
import { t } from "i18next";
import { TwoFaCodeInput } from "./twoFa-input";

const inputStyle =
  "font-bold text-black block w-full px-3 py-2 bg-white border text-sm placeholder-black border-[#76C14E] focus:outline-none";

export const MobileLoginForm = (props: any) => {
  const {
    postCode = "852",
    setPostCode,
    number,
    setNumber,
    twoFaCode,
    setTwoFaCode,
    handleTwoFaCode,
  } = props;

  return (
    <div>
      <div className="flex flex-row md:flex-col mt-5">
        <input
          required
          type="text"
          value={postCode}
          className={`${inputStyle} flex-1 border-r-0 border-r-[#D9D9D9]`}
          placeholder={t("login.mobileForm.postCode")}
          onChange={(e) => setPostCode(e.target.value)}
          maxLength={3}
        />
        <div className="bg-[#D9D9D9] h-5 w-[1px] self-center absolute ml-14" />
        <input
          required
          type="text"
          value={number}
          className={`${inputStyle} flex-[7_7_0%] border-l-0`}
          placeholder={t("login.mobileForm.phoneNumber")}
          onChange={(e) => setNumber(e.target.value)}
        />
      </div>

      <TwoFaCodeInput
        twoFaCode={twoFaCode}
        setTwoFaCode={setTwoFaCode}
        handleTwoFaCode={handleTwoFaCode}
      />
    </div>
  );
};
