import React from "react";
import { Provider } from "mobx-react";
import Routers from "./router";
import { stores, StoresContext } from "./stores";
import * as Configs from "./config";
import { WagmiConfig } from "wagmi";
import { Web3Modal } from "@web3modal/react";

function App() {
  return (
    <Provider {...stores}>
      <StoresContext.Provider value={stores}>
        <WagmiConfig client={Configs.wagmiClient}>
          <Routers />
        </WagmiConfig>

        <Web3Modal
          projectId={Configs.projectId}
          ethereumClient={Configs.ethereumClient}
          themeMode={"light"}
        />
      </StoresContext.Provider>
    </Provider>
  );
}

export default App;
