import React from "react";
import { LoadingIcon } from "src/components/loading-icon";
const { Table } = require("antd");

export const TableList = (props: any) => {
  const { dataList, columns, onTablePageChange, loading = false } = props;
  if (!dataList || dataList.length === 0)
    return (
      <div className="flex w-screen justify-center">
        <LoadingIcon />
      </div>
    );

  if (!columns || columns.length === 0) return null;

  return (
    <Table
      size="middle"
      dataSource={!dataList || dataList.length === 0 ? [] : dataList}
      rowKey={(row: any) => row?.id || row?._id || row?.key || row}
      columns={columns}
      loading={loading}
      onChange={onTablePageChange}
      sortDirections={"ascend"}
    />
  );
};
