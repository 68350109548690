import React from "react";
import { Carousel } from "antd";
import { Event } from "./event";

export const EventCarousel = (props: any, autoplay = false) => {
  return (
    <div className="relative clear-both">
      <Carousel>
        <Event {...props} />
      </Carousel>
    </div>
  );
};
