import { t } from "i18next";
import React from "react";
import { Empty } from "antd";
import { Coupon } from "./coupon";

export const Coupons = (props: any) => {
  const { couponData } = props;

  return (
    <div className="w-full mx-auto mt-[20px] clear-both">
      <div className="grid justify-items-center	gap-26 2xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
        {couponData &&
          couponData.length > 0 &&
          couponData.map((item: any, i: any) => {
            const { expiry_date, _id, logo, name, coupon_supplies } = item;
            const remaining = coupon_supplies.filter(
              (item: any) => !item.is_used
            ).length;
            return (
              <Coupon
                index={i}
                key={i}
                expiry_date={expiry_date}
                remaining={remaining}
                id={_id}
                logo={logo}
                name={name}
              />
            );
          })}
      </div>

      {!couponData ||
        (couponData.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span className="font-bold tracking-wider text-gray">
                {t(`wallet.noItem`)}
              </span>
            }
          />
        ))}
    </div>
  );
};
