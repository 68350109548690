import React from "react";
import { Container } from "../../components/container";
import { LoginSection } from "./login-section";

export const Login = (props: any) => {
  return (
    <Container>
      <LoginSection {...props} />
    </Container>
  );
};
