import React from "react";
import { t } from "i18next";
import { TwoFaCodeInput } from "./twoFa-input";

const inputStyle =
  "font-bold text-black mt-5 block w-full px-3 py-2 bg-white border text-sm placeholder-black border-[#76C14E] focus:outline-none";

export const EmailLoginForm = (props: any) => {
  const { email, setEmail, twoFaCode, setTwoFaCode, handleTwoFaCode } = props;

  return (
    <div>
      <input
        type="text"
        value={email}
        className={inputStyle}
        placeholder={t("login.mobileForm.email")}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TwoFaCodeInput
        twoFaCode={twoFaCode}
        setTwoFaCode={setTwoFaCode}
        handleTwoFaCode={handleTwoFaCode}
      />
    </div>
  );
};
