import React from "react";
import { t } from "i18next";
import { Container, ReverseButton } from "src/components";
import { history } from "src/stores";

export const Error = (props: any) => {
  return (
    <Container>
      <div className="m-auto mt-[60px] w-[auto] md:w-[480px] text-center">
        <div className="flex flex-col items-center">
          <img
            src="assets/error_icon.png"
            alt="issue-coupon-error"
            className="object-contain w-[60px] h-[60px]"
          />
          <h2 className="mt-xl mb-xl text-[16px] text-black tracking-[20px] pl-[20px]">
            {t("status.error")}
          </h2>

          <ReverseButton
            handleAction={() => history.push("/checkout")}
            buttonText={`button.goToCheckout`}
            customStyle={`w-[250px] rounded mb-4 py-[8px] sm:text-sm focus:ring-1 sm:text-[12px] tracking-wider text-white bg-primary`}
          />

          <ReverseButton
            handleAction={() => history.push("/")}
            buttonText={`button.goToHome`}
            customStyle={`w-[250px] rounded py-[8px] sm:text-sm focus:ring-1 sm:text-[12px] tracking-wider text-primary bg-white border border-primary`}
          />
        </div>
      </div>
    </Container>
  );
};
