import React, { useEffect, useState } from "react";
import { history, useCouponStore } from "src/stores";
import { Container, openWarningNotification } from "src/components";
import { CouponData } from "./coupon-data";
// import { couponsData } from "src/sample-data/coupon";
import { t } from "i18next";

export const CouponDetail = (props: any) => {
  const { match } = props;
  const couponId = match.params.id || null;
  const [coupon, setCoupon] = useState<any>([]);
  const { getCoupon } = useCouponStore();

  useEffect(() => {
    if (!couponId) return history.push("/");

    getCoupon(`?coupon_id=${couponId}`).then((result: any) => {
      if (!result) return openWarningNotification(t("messages.noCouponFound"));
      setCoupon([result]);
    });
  }, [getCoupon, couponId, setCoupon]);

  return (
    <Container customStyles={`mb-0`}>
      <CouponData coupon={coupon} />
    </Container>
  );
};
