import React, { useCallback, useState, useEffect } from "react";
import { hasLogin } from "src/utils/token";
import { history, useUserStore } from "../../stores";

export const AuthProvider = (props: any) => {
  const { children } = props;
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState({
    phone_country: "",
    phone_num: "",
    email: "",
    nickname: "",
    referral_code: "",
    avatar: "",
  });

  const { getUser } = useUserStore();

  const checkLogin = useCallback(async () => {
    let accessToken: any = hasLogin();
    if (!accessToken) {
      setIsLogin(false);
      return history.push("/");
    }
    return setIsLogin(true);
  }, [setIsLogin]);

  useEffect(() => {
    if (!isLogin) {
      checkLogin();
      return;
    }

    getUser().then((result: any) => {
      if (!result) return;
      setUser(result);
    });
  }, [isLogin, getUser, setUser, checkLogin]);

  return React.cloneElement(children, {
    isLogin,
    user,
    setUser,
  });
};
