export const setToken = (token: string) =>
  localStorage.setItem("reverse-token", token);

export const getToken = (): string =>
  localStorage.getItem("reverse-token") ?? "";

export const removeToken = () => {
  localStorage.removeItem("reverse-token");
};

export const hasLogin = (): boolean => (getToken().length > 0 ? true : false);
