import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { openWarningNotification, ReverseButton } from "src/components";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
import { supportedTokens, getChain, NETWORK } from "src/config";
import { useNetwork } from "wagmi";
import { useSwitchNetwork } from "wagmi";
import UserWalletBalance from "./wallet-balance";
import { useTransactionStore } from "src/stores";
import { ActionButton } from "./action-button";

export const CryptoPaymentForm = (props: any) => {
  const { transaction } = props;
  const inputStyle =
    "font-bold text-xs text-black block w-full px-2 py-2 bg-white border rounded placeholder-black focus:outline-none tracking-[0.5px]";
  const tokenIconStyle =
    "h-8 w-8 rounded-full ring-2 ring-white cursor-pointer";
  const imgViewStyle = "flex flex-row justify-evenly w-full my-10 px-[50px]";

  const [currentSelectedTokenIndex, setCurrentSelectedTokenIndex] =
    useState<any>(0);
  const [tokenBalance, setTokenBalance] = useState(0);

  const { switchNetwork, error }: any = useSwitchNetwork();
  const { chain }: any = useNetwork();
  const { address, isConnected }: any = useAccount();
  const { open } = useWeb3Modal();

  const { createPaymentOrder, updatePaymentOrder, getLatestTokenPrice } =
    useTransactionStore();

  const networkName = useMemo(() => {
    if (!chain || chain.unsupported) {
      return NETWORK === "mainnet" ? "homestead" : "goerli"; //bsc
    }
    return chain?.network;
  }, [chain]);

  //check if default value of current selected network
  const paymentToken = useMemo(() => {
    if (!supportedTokens) return null;
    let token: any = supportedTokens[networkName][currentSelectedTokenIndex]
      ? supportedTokens[networkName][currentSelectedTokenIndex]
      : supportedTokens[networkName][0]; //taken first token as defalut

    return token;
  }, [networkName, currentSelectedTokenIndex]);

  const tokenSelectionList = useMemo(() => {
    if (!supportedTokens) return null;
    let tokenList = supportedTokens[networkName]; //current selected token list
    return tokenList;
  }, [networkName]);

  // Listen to switch network error
  useEffect(() => {
    if (!error) return;
    error &&
      openWarningNotification(t(`notification.warning.userCancelledRequest`));
  }, [error]);

  const handleNetwork = (networkName: string) => {
    if (!address) return open();
    const { chainId }: any = getChain(networkName);
    switchNetwork(chainId);
  };

  return (
    <div className="px-8 pb-8 flex flex-col m-auto w-[400px] justify-center clear-both">
      <h4 className="text-[16px] mb-10 text-center tracking-[18px]">
        {t("payment.crypto")}
      </h4>
      {/* Wallet Address */}
      <div className="block pt-3 pb-4">
        {address && isConnected && (
          <input
            type="text"
            value={`${address}`}
            className={`${inputStyle}`}
            readOnly
          />
        )}
      </div>
      {/* Supported Network */}
      <div className="flex flex-row justify-between">
        {supportedTokens &&
          Object.keys(supportedTokens).map((tokenName: any, index: number) => {
            //bsc, homestead(erc)
            return (
              <p
                key={index}
                onClick={() => handleNetwork(tokenName)}
                className={`text-[12px] tracking-wider cursor-pointer ${
                  tokenName === networkName &&
                  "underline underline-offset-[12px] decoration-[#76C14E]"
                }`}
              >
                {tokenName === "homestead" ? "ERC" : tokenName.toUpperCase()}
              </p>
            );
          })}
      </div>
      {/* Supported Tokens Icons */}
      <div className={imgViewStyle}>
        {tokenSelectionList &&
          tokenSelectionList.map((option: any, index: any) => {
            return (
              <div
                key={index}
                onClick={() => setCurrentSelectedTokenIndex(index)}
              >
                <img
                  className={
                    index === currentSelectedTokenIndex
                      ? `!ring-[#233F87] ${tokenIconStyle}`
                      : `${tokenIconStyle}`
                  }
                  src={`/assets/${option.name}_icon.png`}
                  alt="token-icon"
                />
              </div>
            );
          })}
      </div>
      {address && isConnected && (
        <UserWalletBalance
          chain={chain}
          address={address}
          setTokenBalance={setTokenBalance}
          token={supportedTokens[networkName][currentSelectedTokenIndex]}
        />
      )}
      {/* Login Button */}
      <div className="">
        {!isConnected && (
          <ReverseButton
            handleAction={() => open()}
            fullWidth={true}
            buttonText={
              !address
                ? `${
                    paymentToken.name
                      ? `button.connectToPayWith${paymentToken.name}`
                      : "button.pleaseSelectToken"
                  }`
                : `button.pleaseSelectToken`
            }
            customStyle={`px-[5px] py-[8px] sm:text-sm focus:ring-1 sm:text-[12px] tracking-wider`}
          />
        )}

        {/* Payment Button */}
        {isConnected && tokenSelectionList && (
          <ActionButton
            chain={chain}
            networkName={networkName}
            transaction={transaction}
            userWallet={address}
            tokenBalance={tokenBalance}
            selectedToken={paymentToken}
            createPaymentOrder={createPaymentOrder}
            updatePaymentOrder={updatePaymentOrder}
            getLatestTokenPrice={getLatestTokenPrice}
            setCurrentSelectedTokenIndex={setCurrentSelectedTokenIndex}
          />
        )}
      </div>
    </div>
  );
};
