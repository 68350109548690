import { t } from "i18next";
import React, { useEffect } from "react";
import { LoadingIcon } from "src/components";
import { useBalance } from "wagmi";

export default function UserWalletBalance(props: any) {
  const { chain, address, token = {}, setTokenBalance } = props;

  const { data, isLoading }: any = useBalance({
    address: address,
    ...(token && { token: token.address }),
    watch: false,
    chainId: chain.id,
  });

  useEffect(() => {
    if (!data) return;
    if (data.formatted) {
      setTokenBalance(data.formatted);
    }
  }, [data, setTokenBalance]);

  return (
    <div className="text-center">
      {isLoading && <LoadingIcon size={10} />}
      {/* {`balance: ${: Number(data?.formatted) === 0 ? ("0 " */}
      <p className="text-xs">
        {!isLoading && (
          <React.Fragment>
            <span className="font-bold pr-3">{t("wallet.balance")}</span>
            <span className="text-primary text-[25px]">
              {Number(data?.formatted).toFixed(4)}
            </span>
            <span> {data?.symbol}</span>
          </React.Fragment>
        )}
      </p>
    </div>
  );
}
