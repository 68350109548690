import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import {
  errorHandling,
  isResultSuccess,
  refreshNewToken,
  setHeader,
} from "./api";

export class ReferralStore {
  async applyReferralCode(referralCode: string) {
    const url = `${Configs.apiUrl}/referral/update-referral`;
    try {
      const result: any = await axios.put(
        url,
        { referral_code: referralCode },
        setHeader()
      );
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async checkReferralStatus() {
    const url = `${Configs.apiUrl}/referral/check-referral`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_REFERRAL = "referralStore";
